const cursor = document.getElementById('cursor');
const root = document.documentElement;

function mouseXY() {
    document.addEventListener('mousemove', evt => {
        let x = evt.clientX / innerWidth;
        let y = evt.clientY / innerHeight;
        root.style.setProperty('--mouse-x', x);
        root.style.setProperty('--mouse-y', y);
    })
}

window.addEventListener('pointermove', function (event) {
    if (event.pointerType === 'touch') {
        document.body.dataset.cursorTouch = "touch";
    } else {
        document.body.dataset.cursorTouch = "cursor";
        mouseXY();
    }
})

document.addEventListener('mouseover', function (event) {
    if (event.target.closest('a')) {
        document.body.dataset.linkhover = "true";
        document.body.dataset.cursor = event.target.closest('a').dataset.cursor;
    }
    else {
        document.body.dataset.linkhover = "false";
    }
});

