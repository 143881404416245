var scrollpositionBuehne1 = 0;
var scrollpositionBuehne2 = 0;
var buehne1 = document.getElementById('buehnen__buehne--1');
var buehne2 = document.getElementById('buehnen__buehne--2');
var bodyProjekte = document.body;
var buehneAktiv = parseInt(bodyProjekte.getAttribute('data-buehne-aktiv'));

buehne1.addEventListener('mouseenter', function () {
    document.body.setAttribute('data-buehne-hover', 1);
    document.body.setAttribute('data-cursor', 'pfeilkreis')
});
buehne1.addEventListener('mouseleave', function () {
    document.body.setAttribute('data-buehne-hover', false);
});


function buehneAktivieren(buehneAktivierenNummer) {
    console.log('buehneAktivieren ' + buehneAktivierenNummer);
    if (buehneAktivierenNummer != buehneAktiv) {
        // Direkt mit Beginn der Animation wird data-buehnen-animiert true
        bodyProjekte.setAttribute('data-buehnen-animiert', 'true')

        setTimeout(() => {
            // Nach Ablauf der Animation wird das Attribute data-buehnen-animiert zurückgeändert
            bodyProjekte.setAttribute('data-buehnen-animiert', 'false')
            // Für buehne-aktiv 2 darf die Scrollposition erst zum Ende der Animation geändert werden,
            // weil erst zum Ende der Animation das Layout geändert wird (1: fixed, 2: relative)
            if (buehneAktiv == 2) {
                buehne1.style.top = -scrollpositionBuehne1 + 'px';
                window.scrollTo(0, 0);
            }
        }, 1000)
    }
    if (buehneAktivierenNummer == 1 && buehneAktiv != 1) {
        scrollpositionBuehne2 = window.scrollY;
        bodyProjekte.setAttribute('data-buehne-aktiv', '1')
        buehne1.style.top = '';
        buehneAktiv = 1;
        // Für buehne-aktiv 1 muss die Scrollposition sofort geändert werden,
        // weil mit Beginn der Animation das Layout geändert wird (1: relative, 2: fixed)
        buehne2.style.top = -scrollpositionBuehne2 + 'px';
        window.scrollTo(0, scrollpositionBuehne1);
    }
    if (buehneAktivierenNummer == 2 && buehneAktiv != 2) {
        // Die Scrollposition muss gemessen werden, bevor das Layout sich ändert
        // Änderungen an den data Attributen führen sofort zu einer Änderung des Layouts
        scrollpositionBuehne1 = window.scrollY;
        buehne2.style.top = '';
        bodyProjekte.setAttribute('data-buehne-aktiv', '2')
        buehneAktiv = 2;
    }
}

var buehneDelayed;
barba.init({
    transitions: [
        {
            name: 'zuBuehne2',
            // before() {
            //     console.log('zuBuehne2 before');
            // },
            enter() {
                setTimeout(() => {
                    buehneAktivieren(2);
                }, 10)
            },
            to: {
                namespace: [
                    'projekt',
                    'profil',
                    'werkliste',
                    'kontakt',
                ]
            }
        },
        {
            name: 'zuBuehne1',
            async enter() {
                buehneAktivieren(1);
                console.log('zuBuehne1 enter');
                // damit die büehne noch angezeigt wird beim Schliessen, wird ein delay mit der selben Zeit wie die animation gesetzt.
                // Der delay verzögert den nächsten Schritt im Lifecycle um die angegebene Zeit
                console.log('delay gestartet');
                buehneDelayed = delay(1000, 'delay beendet');
                await buehneDelayed.promise
                    // .then((value) => console.log(value))
                    // .catch(() => console.error('delay gestoppt'));
            },
            to: {
                namespace: [
                    'projekte'
                ]
            }
        }
    ]
});

// https://dev.to/oliverjumpertz/javascript-quick-tip-create-a-cancelable-promise-delay-52fb
const delay = (delay, value) => {
    let timeout;
    let _reject;
    const promise = new Promise((resolve, reject) => {
        _reject = reject;
        timeout = setTimeout(resolve, delay, value);
    });
    return {
        promise,
        cancel() {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
                _reject();
                _reject = null;
            }
        }
    };
};


// Aktiven Navigationspunkt einstellen
barba.hooks.enter((data) => {
    menuepunkt = document.getElementById('menue__link--' + data.current.container.getAttribute('data-navigation-id'));
    menuepunkt.classList.remove('menue__link--aktiv');

    menuepunkt = document.getElementById('menue__link--' + data.next.container.getAttribute('data-navigation-id'));
    menuepunkt.classList.add('menue__link--aktiv');
});

// Die Startseite soll weiterhin die Scrollposition beibehalten. Bei den anderen geht es immer wieder auf die top Position. (Problem taucht auf aufgrund von Barba)
barba.hooks.beforeEnter((data) => {
    emailAdressen();
    if (!(data.next.namespace === 'projekte' || data.current.namespace === 'projekte')) {
        window.scrollTo(0, 0);
    }
});

// Ist die Bühne noch animiert von 2 zu 1, und wird ein Bühne-2-Link gedrückt,
// muss der delay abgebrochen werden, weil sonst Barba nicht funktioniert
// und die Seite hart neu geladen wird
// TODO Noch ungelöst. Siehe Issue #62 (https://github.com/profitlich-ch/studioc-architekten.ch/issues/13) 
// barba.hooks.before((data) => {
//     console.log(before);
// });